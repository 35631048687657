import PropTypes from "prop-types";
import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { styled } from "@material-ui/styles";
import ActionButton from "../Common/ActionButton";
import DenseTableCell from "../Common/DenseTableCell";
import LoadingTableBody from "../Common/LoadingTableBody";

const StyledTable = styled(Table)({
  width: "100%",
  "& th": {
    whiteSpace: "nowrap",
  },
});

const AccountList = (props) => {
  const filterTerm = props.filterTerm?.toLowerCase();

  const accounts = !props.filterTerm
    ? props.accounts
    : props.accounts.filter(
        (account) =>
          account.email.toLowerCase().includes(filterTerm) ||
          account.lastName.toLowerCase().includes(filterTerm) ||
          account.firstName.toLowerCase().includes(filterTerm)
      );

  return (
    <TableContainer className={props.className}>
      <StyledTable size="small">
        <TableHead>
          <TableRow>
            <DenseTableCell>Application</DenseTableCell>
            <DenseTableCell>ID</DenseTableCell>
            <DenseTableCell>e-mail</DenseTableCell>
            <DenseTableCell>Last name</DenseTableCell>
            <DenseTableCell>First name</DenseTableCell>
            <DenseTableCell>Last access time</DenseTableCell>
            <DenseTableCell />
          </TableRow>
        </TableHead>
        <LoadingTableBody
          loading={props.loading}
          colSpan={6}
          loadingMessage="Fetching accounts. Please wait."
        >
          {accounts
            .sort((a, b) => b.lastAccessTime - a.lastAccessTime)
            .map((account) => (
              <TableRow key={account.accountId}>
                <DenseTableCell>{account.applicationId}</DenseTableCell>
                <DenseTableCell>{account.accountId}</DenseTableCell>
                <DenseTableCell>{account.email}</DenseTableCell>
                <DenseTableCell>{account.lastName}</DenseTableCell>
                <DenseTableCell>{account.firstName}</DenseTableCell>
                <DenseTableCell>
                  {account.lastAccessTime?.toLocaleString()}
                </DenseTableCell>
                <DenseTableCell>
                  <ActionButton
                    tooltip="Edit account"
                    onClick={() => {
                      props.onEditAccount?.(account.accountId);
                    }}
                  >
                    <EditIcon />
                  </ActionButton>
                </DenseTableCell>
              </TableRow>
            ))}
        </LoadingTableBody>
      </StyledTable>
    </TableContainer>
  );
};

AccountList.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string.isRequired,
      applicationId: PropTypes.string.isRequired,
      lastAccessTime: PropTypes.instanceOf(Date),
    })
  ),
  filterTerm: PropTypes.string,
  onEditAccount: PropTypes.func,
  loading: PropTypes.bool,
};

AccountList.defaultProps = {
  accounts: [],
  loading: false,
};

export default AccountList;
