import { useState } from "react";
import Error from "../Common/Error";
import { reshapeApplication } from "../../data/reshapeApplications";
import { useFetchApplications } from "../../hooks/useQueries";
import useResourceEditing from "../../hooks/useResourceEditing";
import ApplicationDetailsDialog from "./ApplicationDetailsDialog";
import ApplicationList from "./ApplicationList";
import ApplicationsToolbar from "./ApplicationsToolbar";
import MoveAccountsDialog from "./MoveAccountsDialog";

const PopulatedApplicationDetailsDialog = (props) => {
  const application = props.application;

  return (
    <ApplicationDetailsDialog
      open={props.open}
      applicationId={application.applicationId}
      userActivityEnabled={application.enabled}
      applicationName={application.humanName}
      subApplicationEnabled={application.subApplication}
      applications={props.applications}
      subApplicationAccountSource={application.superApplicationId}
      visibilityMode={application.accessMode}
      accessUrl={application.url}
      logoUrl={application.webInfo?.logoUrl}
      foregroundColor={application.webInfo?.foreColor}
      backgroundColor={application.webInfo?.backColor}
      linkColor={application.webInfo?.linkColor}
      requirementText={application.webInfo?.requirement}
      defaultParameters={application.paramDefaults?.textParam}
      sharedSecret={application.sharedSecret}
      locked={!!application.applicationId}
      saving={props.saving}
      error={props.error}
      onSave={props.onSave}
      onCancel={props.onCancel}
    />
  );
};

const Applications = () => {
  const [showInactive, setShowInactive] = useState(false);
  const [moveAccountsDialogOpen, setMoveAccountsDialogOpen] = useState(false);

  const { isLoading, isError, applications, error } = useFetchApplications();

  const {
    resourceState,
    editResource,
    addResource,
    updateResource,
    cancelResourceEditing,
  } = useResourceEditing("applications");

  if (isError) {
    const message = `Oops! Failed to fetch applications. ${error.message}. Try refreshing the browser.`;

    return <Error message={message} />;
  }

  const handleEditApplication = (applicationId) => {
    const application = applications.find(
      (application) => application.applicationId === applicationId
    );

    editResource(application);
  };

  const handleSaveApplication = (application) => {
    const resource = reshapeApplication(application);

    if (!resourceState.data.applicationId) {
      addResource.mutate(resource);
    } else {
      updateResource.mutate({
        ...resource,
        id: resource.applicationId,
      });
    }
  };

  return (
    <div>
      <ApplicationsToolbar
        showInactive={showInactive}
        onShowInactiveChange={(e) => {
          setShowInactive(e);
        }}
        onAddApplication={() => {
          editResource({});
        }}
        onMoveAccounts={() => {
          setMoveAccountsDialogOpen(true);
        }}
      />
      <ApplicationList
        applications={applications}
        showInactive={showInactive}
        loading={isLoading}
        onEditApplication={handleEditApplication}
      />
      {resourceState.data && (
        <PopulatedApplicationDetailsDialog
          open={true}
          applications={applications}
          application={resourceState.data}
          saving={resourceState.saving}
          error={resourceState.error}
          onSave={handleSaveApplication}
          onCancel={cancelResourceEditing}
        />
      )}
      {moveAccountsDialogOpen && (
        <MoveAccountsDialog
          open={moveAccountsDialogOpen}
          applications={applications}
          onClose={() => {
            setMoveAccountsDialogOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default Applications;
