import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useNavigate } from "react-router-dom";

const AuxiliaryMenu = (props) => {
  const [anchorElement, setAnchorElement] = useState();

  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(undefined);
  };

  const handleNavigate = (to) => {
    navigate(to);

    handleCloseMenu();
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleOpenMenu}>
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        open={!!anchorElement}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            handleNavigate("/activity");
          }}
        >
          Activity
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleNavigate("/accounts");
          }}
        >
          Accounts
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleNavigate("/applications");
          }}
        >
          Applications
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleNavigate("/import");
          }}
        >
          Import
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleNavigate("/system-mail");
          }}
        >
          System Mail
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleNavigate("/mail-templates");
          }}
        >
          Mail Templates
        </MenuItem>
      </Menu>
    </>
  );
};

export default AuxiliaryMenu;
