import { CancelToken } from "axios";
import api from "../api/api";
import { reshapeAccounts } from "../data/reshapeAccounts";
import { reshapeActivities } from "../data/reshapeActivities";
import { reshapeApplications } from "../data/reshapeApplications";
import { reshapeMailDestinations } from "../data/reshapeMailDestinations";
import { reshapeMailTemplates } from "../data/reshapeMailTemplates";
import { reshapeQueries } from "../data/reshapeQueries";
import useQuery from "./useQuery";

const fetchData = (url, options) => {
  const source = CancelToken.source();

  const promise = api.fetchData(url, {
    ...options,
    cancelToken: source.token,
  });

  promise.cancel = () => source.cancel("Query was canceled by React Query");

  return promise;
};

export const useFetchAccounts = (
  accountIds,
  applicationId,
  limit,
  filterTerm
) => {
  const query = useQuery(
    ["accounts", accountIds, applicationId, limit, filterTerm],
    () =>
      fetchData("/accounts", {
        params: {
          accountIds,
          applicationId,
          limit,
          filterTerm,
        },
      })
  );

  return {
    ...query,
    accounts: query.data && reshapeAccounts(query.data.data),
    unlimitedCount: query.data?.data.totalMatch,
  };
};

export const useFetchActivities = (afterTime, limit) => {
  const query = useQuery(["activity", afterTime, limit], () =>
    fetchData("/activity", {
      params: {
        afterTime,
        limit,
      },
    })
  );

  return {
    ...query,
    activities: query.data && reshapeActivities(query.data.data),
  };
};

export const useFetchApplications = (applicationIds, includeExtra) => {
  const query = useQuery(["applications", applicationIds, includeExtra], () =>
    fetchData("/applications", {
      params: {
        appIds: applicationIds,
        includeExtra,
      },
    })
  );

  return {
    ...query,
    applications: query.data && reshapeApplications(query.data.data),
  };
};

export const useFetchMailDestinations = (destinationIds) => {
  const query = useQuery(["mail-destinations", destinationIds], () =>
    fetchData("/mail-destinations", {
      params: {
        destIds: destinationIds,
      },
    })
  );

  return {
    ...query,
    mailDestinations: query.data && reshapeMailDestinations(query.data.data),
  };
};

export const useFetchMailTemplates = (templateIds) => {
  const query = useQuery(["mail-templates", templateIds], () =>
    fetchData("/mail-templates", {
      params: {
        templateIds,
      },
    })
  );

  return {
    ...query,
    mailTemplates: query.data && reshapeMailTemplates(query.data.data),
  };
};

export const useFetchMessages = () => {
  const query = useQuery("messages", () => fetchData("/messages"));

  return {
    ...query,
    messages: query.data?.data,
  };
};

export const useFetchQueries = (queryIds) => {
  const query = useQuery(["queries", queryIds], () =>
    fetchData("/queries", {
      params: {
        queryIds,
      },
    })
  );

  return {
    ...query,
    queries: query.data && reshapeQueries(query.data.data),
  };
};
