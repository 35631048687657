import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import LockingDialogActions from "../Common/LockingDialogActions";
import PrivateTextInput from "../Common/PrivateTextInput";
import ColorPicker from "./ColorPicker";

const StyledDialogForm = styled(DialogForm)(({ theme }) => ({
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
  },
  "& .sub-application": {
    display: "flex",
    margin: theme.spacing(1, 0, 1, 0),
    "& .controls": {
      display: "flex",
      flex: 1,
      marginLeft: `${theme.spacing(2)}px`,
      "& .account-source": {
        flex: 1,
      },
    },
  },
}));

const ColorPickers = (props) => (
  <div className={props.className}>
    <FormControlLabel
      control={
        <div className="swatches">
          <FormControlLabel
            control={
              <ColorPicker
                color={props.foregroundColor}
                onChange={props.onForegroundColorChange}
              />
            }
            label="Foreground"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <ColorPicker
                color={props.backgroundColor}
                onChange={props.onBackgroundColorChange}
              />
            }
            label="AppBar background"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <ColorPicker
                color={props.linkColor}
                onChange={props.onLinkColorChange}
              />
            }
            label="AppBar text"
            labelPlacement="bottom"
          />
        </div>
      }
      label="Colors"
      labelPlacement="start"
    />
  </div>
);

const StyledColorPickers = styled(ColorPickers)(({ theme }) => ({
  display: "flex",
  marginTop: `${theme.spacing(1)}px`,
  "& .color-picker + .MuiFormControlLabel-label": {
    fontSize: "small",
  },
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
  },
  "& .swatches": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    marginLeft: `${theme.spacing(2)}px`,
  },
}));

const ApplicationDetailsDialog = (props) => {
  const [applicationId, setApplicationId] = useState(props.applicationId);
  const [userActivityEnabled, setUserActivityEnabled] = useState(
    props.userActivityEnabled
  );
  const [applicationName, setApplicationName] = useState(props.applicationName);
  const [subApplicationEnabled, setSubApplicationEnabled] = useState(
    props.subApplicationEnabled
  );
  const [
    subApplicationAccountSource,
    setSubApplicationAccountSource,
  ] = useState(props.subApplicationAccountSource);
  const [visibilityMode, setVisibilityMode] = useState(props.visibilityMode);
  const [accessUrl, setAccessUrl] = useState(props.accessUrl);
  const [logoUrl, setLogoUrl] = useState(props.logoUrl);
  const [foregroundColor, setForegroundColor] = useState(props.foregroundColor);
  const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
  const [linkColor, setLinkColor] = useState(props.linkColor);
  const [requirementText, setRequirementText] = useState(props.requirementText);
  const [defaultParameters, setDefaultParameters] = useState(
    props.defaultParameters
  );
  const [sharedSecret, setSharedSecret] = useState(props.sharedSecret);
  const [locked, setLocked] = useState(props.locked);

  const checkValidity = () =>
    applicationId &&
    applicationName &&
    (!subApplicationEnabled || props.applications.length);

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Application details</DialogTitle>
      <StyledDialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <TextField
          label="ID"
          value={applicationId ?? ""}
          required
          fullWidth
          helperText="Do not change the ID accidentally. All existing users will be locked out."
          disabled={locked}
          onChange={(e) => {
            setApplicationId(e.target.value);
          }}
        />

        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={userActivityEnabled}
                onClick={() => {
                  setUserActivityEnabled(
                    (userActivityEnabled) => !userActivityEnabled
                  );
                }}
              />
            }
            label="User activity enabled for this application"
            disabled={locked}
          />
          <FormHelperText>
            Disabling will stop all user activity for this application
          </FormHelperText>
        </FormControl>

        <TextField
          label="Name"
          value={applicationName ?? ""}
          required
          fullWidth
          onChange={(e) => {
            setApplicationName(e.target.value);
          }}
        />

        <FormControl className="sub-application">
          <FormControlLabel
            control={
              <div className="controls">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={subApplicationEnabled}
                      onClick={(e) => {
                        setSubApplicationEnabled(
                          (subApplicationEnabled) => !subApplicationEnabled
                        );
                      }}
                    />
                  }
                  label="Enabled"
                  disabled={locked}
                />
                <FormControl
                  disabled={locked}
                  fullWidth
                  className="sub-application-account-source"
                >
                  <InputLabel
                    htmlFor="sub-application-account-source"
                    required
                    shrink
                  >
                    Account source
                  </InputLabel>
                  <Select
                    id="account-source"
                    native
                    value={subApplicationAccountSource ?? ""}
                    onChange={(e) => {
                      setSubApplicationAccountSource(e.target.value);
                    }}
                  >
                    {props.applications
                      .sort((a, b) =>
                        a.applicationId.localeCompare(b.applicationId)
                      )
                      .map((application) => (
                        <option
                          key={application.applicationId}
                          value={application.applicationId}
                        >
                          {application.applicationId}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </div>
            }
            label="Sub-application"
            labelPlacement="start"
            className="sub-application"
          />
          <FormHelperText>
            Once enabled, sub-application mode cannot be disabled
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="visibility-mode" required>
            Visibility mode
          </InputLabel>
          <Select
            native
            inputProps={{ id: "visibility-mode" }}
            value={visibilityMode}
            onChange={(e) => {
              setVisibilityMode(e.target.value);
            }}
          >
            <option value="private">Private</option>
            <option value="on request">On request</option>
            <option value="public">Public</option>
          </Select>
        </FormControl>

        <TextField
          label="Access URL"
          value={accessUrl ?? ""}
          fullWidth
          onChange={(e) => {
            setAccessUrl(e.target.value);
          }}
        />
        <TextField
          label="Logo URL"
          value={logoUrl ?? ""}
          fullWidth
          helperText="The logo URL is typically relative (e.g. TBX/MyLogo.png)"
          onChange={(e) => {
            setLogoUrl(e.target.value);
          }}
        />

        <StyledColorPickers
          foregroundColor={foregroundColor || "#000"}
          backgroundColor={backgroundColor || "#000"}
          linkColor={linkColor || "#000"}
          onForegroundColorChange={setForegroundColor}
          onBackgroundColorChange={setBackgroundColor}
          onLinkColorChange={setLinkColor}
        />

        <TextField
          label="Requirement text"
          value={requirementText ?? ""}
          fullWidth
          helperText="Requirement text is shown on the account creation page. Keep it short."
          onChange={(e) => {
            setRequirementText(e.target.value);
          }}
        />
        <TextField
          label="Default parameters"
          value={defaultParameters ?? ""}
          fullWidth
          onChange={(e) => {
            setDefaultParameters(e.target.value);
          }}
        />
        <PrivateTextInput
          label="Shared secret"
          value={sharedSecret ?? ""}
          fullWidth
          helperText="Spaces are not allowed. Do not reveal the secret to anyone."
          onChange={(e) => {
            setSharedSecret(e.target.value);
          }}
        />
      </StyledDialogForm>
      <LockingDialogActions locked={locked} onLockChange={setLocked}>
        <Button
          color="primary"
          disabled={props.saving}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={!checkValidity()}
          loading={props.saving}
          onClick={() => {
            props.onSave?.({
              applicationId: applicationId || undefined,
              userActivityEnabled,
              applicationName: applicationName || undefined,
              subApplicationEnabled,
              subApplicationAccountSource,
              visibilityMode,
              accessUrl: accessUrl || undefined,
              logoUrl: logoUrl || undefined,
              colors: {
                foreground: foregroundColor,
                background: backgroundColor,
                link: linkColor,
              },
              requirementText: requirementText || undefined,
              defaultParameters: defaultParameters || undefined,
              sharedSecret: sharedSecret || undefined,
            });
          }}
        >
          Save
        </LoadingButton>
      </LockingDialogActions>
    </Dialog>
  );
};

ApplicationDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  applicationId: PropTypes.string,
  userActivityEnabled: PropTypes.bool,
  applicationName: PropTypes.string,
  subApplicationEnabled: PropTypes.bool,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      applicationId: PropTypes.string.isRequired,
      humanName: PropTypes.string,
    })
  ),
  subApplicationAccountSource: PropTypes.string,
  visibilityMode: PropTypes.string,
  accessUrl: PropTypes.string,
  logoUrl: PropTypes.string,
  foregroundColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  linkColor: PropTypes.string,
  requirementText: PropTypes.string,
  defaultParameters: PropTypes.string,
  sharedSecret: PropTypes.string,
  locked: PropTypes.bool,
  saving: PropTypes.bool,
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

ApplicationDetailsDialog.defaultProps = {
  userActivityEnabled: true,
  subApplicationEnabled: false,
  applications: [],
  visibilityMode: "private",
  foregroundColor: "#000",
  backgroundColor: "#000",
  linkColor: "#000",
  locked: true,
  saving: false,
};

export default ApplicationDetailsDialog;
