import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import { styled } from "@material-ui/styles";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: grey[200],
  padding: `${theme.spacing(0.5)}px`,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const ActionButton = (props) => (
  <Tooltip title={props.tooltip}>
    <StyledIconButton color={props.color} onClick={props.onClick}>
      {props.children}
    </StyledIconButton>
  </Tooltip>
);

ActionButton.propTypes = {
  color: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
};

ActionButton.defaultProps = {
  color: "primary",
};

export default ActionButton;
